import { Dialog, DialogActions, DialogContent, IconButton } from '@mui/material';
import React from 'react'
import closeIcon from 'src/assets/svgIcons/closeIcon.svg';

const CustomDialogie = ({children,openTemplateDialog,performCancel,width}) => {
  return (
    <Dialog
    maxWidth="lg"
    open={openTemplateDialog}
    // onClose={handleTemplateDialogClose}
  >
    <DialogActions sx={{ backgroundColor: '#F2F2F5', padding: 0 }}>
      <IconButton onClick={performCancel}>
        <img
          src={closeIcon}
          alt="Close"
          sx={{ height: '45px', width: '45px' }}
        />
      </IconButton>
    </DialogActions>
    <DialogContent
      sx={{
        backgroundColor: '#F2F2F5',
        justifyContent: 'center',
        m: '0px',
        width:width?width:"400px"
        // p: '20px',
        // paddingLeft: '35px !important',
        // paddingRight: '35px !important'
      }}
    >
   {children}
    </DialogContent>
  </Dialog>
  )
}

export default CustomDialogie
