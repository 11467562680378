// CustomTooltip.js
import React from 'react';
import { Tooltip, tooltipClasses, styled } from '@mui/material';

// Custom styled tooltip
const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#333',
    color: 'white',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    padding: '4px 8px', // Reduced padding
  }
}));

export default CustomTooltip;