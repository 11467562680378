import React, { useState } from 'react'
import TemplateContent from '../TemplateContent'
import EditTemplateLib from '../../NewTemplatesCarosel/EditTemplateLib';

const TemplateLibContainer = ({hasNextPage,libTemplate,handleScroll,loading}) => {
    const [getData, setGetData] = useState();
    const [showCreate, setShowCreate] = useState(false);
    const handleCreateTemplate = (data) =>{
    console.log("libTemplate",data)

    setGetData(data)
    setShowCreate(true)
    }
    const performCancel=()=>{
        setShowCreate(false)
        setGetData()
  
    }
  return (
    <div>
       <TemplateContent
      insideLib={true}
      handleCreateTemplate={handleCreateTemplate}
        handleScroll={handleScroll}
        allTemplates={libTemplate}
        loading={loading}
        hasNextPage={hasNextPage}
        height={"90vh"}
      />
      {showCreate&&
      <EditTemplateLib initialData={getData} show={showCreate} performCancel={performCancel}/>
      }
    </div>
  )
}

export default TemplateLibContainer
