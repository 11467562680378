// ConfirmDialog.js
import React from 'react';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  CircularProgress
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ConfirmDialog = ({ open, onClose, onConfirm, loading, type, action }) => {
  return (
    // <Dialog open={open} sx={{ textAlign: 'center' }}>
    //   <DialogTitle>Confirm Delete</DialogTitle>
    //   <DialogContent>
    //     <DialogContentText>
    //       {`Are you sure you want to delete this ${type}?`}
    //     </DialogContentText>
    //   </DialogContent>
    //   <DialogActions>
    //     <Button onClick={onClose} color="primary">
    //       Cancel
    //     </Button>
    //     <Button onClick={onConfirm} color="primary" disabled={loading}>
    //       {loading ? <CircularProgress size={24} color="primary" /> : 'Delete'}
    //     </Button>
    //   </DialogActions>
    // </Dialog>
    <Dialog
      open={open}
      // onClose={handleCloseConfirmationDialog}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose();
        }
      }}
      sx={{
        '& .MuiDialog-paper': {
          // backgroundColor: '#F2F2F5',
          bgcolor: '#FFF',
          padding: '10px'
        }
      }}
    >
      <DialogTitle
        sx={{
          bgcolor: '#FFF',
          fontSize: 18,
          fontWeight: 600,
          fontFamily: 'Poppins, sans-serif'
        }}
      >
        Confirm {action ? action : 'Delete'}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            position: 'relative'
          }}
        >
          <CloseIcon
            sx={{
              cursor: 'pointer',
              backgroundColor: '#959595',
              borderRadius: '50%',
              color: '#FFF',
              padding: '2px',
              position: 'absolute',
              top: -35,
              right: -20
            }}
            onClick={() => onClose()}
          />
        </Box>
      </DialogTitle>
      <DialogContent sx={{ bgcolor: '#FFF' }}>
        {`Are you sure you want to ${
          action ? action.toLowerCase() : 'delete'
        } this ${type}?`}
      </DialogContent>
      <DialogActions sx={{ bgcolor: '#FFF' }}>
        <Button
          disableRipple
          disableFocusRipple
          sx={{
            backgroundColor: '#FF2F2F',
            fontWeight: 400,
            color: '#FFF',
            '&:hover': {
              backgroundColor: '#FF2F2F'
            }
          }}
          disabled={loading}
          onClick={() => onConfirm()}
        >
          {loading ? (
            <CircularProgress size={22} sx={{ color: '#FFF' }} />
          ) : action ? (
            action
          ) : (
            'Delete'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
