// TemplatePreview.js
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardMedia,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  Menu,
  MenuItem,
  Modal,
  Typography,
  styled
} from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import linkIcon from '../../../assets/TemplateIcons/link.svg';
import callIcon from '../../../assets/TemplateIcons/call.svg';
import replayIcon from '../../../assets/TemplateIcons/replay.svg';
import { format, isToday, isYesterday, isThisWeek } from 'date-fns';
import failedIcon from 'src/assets/failedIcon.png';
import AddIcon from '@mui/icons-material/Add';
// import { makeStyles } from '@mui/styles';
// import { makeStyles } from '@mui/material/styles';

import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import ApiCalls from 'src/utility/ApiCalls';
import Toast from 'src/utility/Toast';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Download from 'src/assets/MediaIcons/Download.svg';
import Close from 'src/assets/MediaIcons/Close.svg';
import Dropdown2 from 'src/assets/svgIcons/side-drop-2.svg';

// Custom styled tooltip
const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#ff2f2f',
    color: 'white',
    boxShadow: theme.shadows[1],
    borderRadius: '6px',
    fontSize: 11,
    padding: '4px 16px' // Reduced padding
  }
}));

const apiKey = process.env.REACT_APP_API_URL;

// function for button icon select
const renderButtonIcon = (type) => {
  let icon;
  switch (type) {
    case 'URL':
      icon = linkIcon;
      break;
    case 'PHONE_NUMBER':
      icon = callIcon;
      break;
    case 'QUICK_REPLY':
      icon = replayIcon;
      break;
    default:
      icon = null;
  }
  return (
    icon && (
      <img
        src={icon}
        alt={`${type} Icon`}
        style={{ marginRight: '5px', height: '15px' }}
      />
    )
  );
};

// const useStyles = makeStyles({
//   checkbox: {
//     '&.Mui-checked': {
//       color: '#27AB69' // Color when checked
//     },
//     '& .MuiIconButton-root': {
//       borderColor: '#27AB69', // Border color
//       backgroundColor: '#27AB69' // Background color when not checked
//     },
//     '& .MuiSvgIcon-root': {
//       backgroundColor: 'rgba(255, 255, 255, 0.5)', // Background color when not checked
//       borderRadius: 4 // Add border radius to the inner box
//     }
//   }
// });

const StyledOverlayBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  color: 'white',
  cursor: 'pointer'
}));

const TemplatePreview = ({
  data,
  bgColor,
  status,
  errorMessage,
  chatPage,
  mediaAvailablity,
  onMediaSelect,
  id,
  setCustomerChats,
  option,
  selectedPerson,
  replyMessage,
  message,
  handleOpenDialog,
  insideTemplate,
  minWidth
}) => {
  // const classes = useStyles();

  const [media, setMedia] = useState([]);
  const [updateUpload, setUpdateUpload] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState('');

  const [currentIndex, setCurrentIndex] = useState(0);

  const [zoomedMedia, setZoomedMedia] = useState(null);
  const [zoomedVideo, setZoomedVideo] = useState(null);

  const [contextMenuPosition, setContextMenuPosition] = useState(null);
  const [currentMessageId, setCurrentMessageId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleContextMenu = (event, id) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget); // Anchor the menu to the clicked position
    setContextMenuPosition({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4
    }); // Set context menu position
    setCurrentMessageId(id); // Set current customer for which the menu is opened
  };

  // Handle click or swipe actions to close the menu
  const handleContextClose = () => {
    setAnchorEl(null);
    setCurrentMessageId(null);
    setContextMenuPosition(null); // Reset the position for the context menu
  };

  // const handleStatusChange = async (id, status) => {
  //   if (status === 'delete') {
  //     setCustomerChats((prevChats) =>
  //       prevChats.filter((chat) => chat._id !== id)
  //     );
  //   }
  //   try {
  //     await ApiCalls.changeMessageStatus(id, status);
  //   } catch (error) {
  //
  //   }
  // };

  const copyToClipboard = (message) => {
    try {
      navigator.clipboard.writeText(message);

      handleContextClose();
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  const handleOpenZoom = (media) => {
    setZoomedMedia(media);
  };
  const handleCloseZoom = () => {
    setZoomedMedia(null);
  };

  const handleDownload = async () => {
    if (zoomedMedia) {
      const imageUrl = `${apiKey}/${zoomedMedia}`;

      try {
        // Fetch the image as a blob
        const response = await fetch(imageUrl);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const blob = await response.blob();

        // Create a link element
        const link = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        link.href = url;
        link.download = 'image.jpg'; // Set the desired filename

        // Append to the body
        document.body.appendChild(link);

        // Trigger the download
        link.click();

        // Clean up
        link.remove();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Download failed:', error);
      }
    } else {
      console.error('Image URL is not provided');
    }
  };

  const handleZoomVideo = (media) => {
    setZoomedVideo(media);
  };

  const handleCloseVideo = () => {
    setZoomedVideo(null);
  };

  const handleVideoDownload = async () => {
    if (zoomedVideo) {
      const videoUrl = `${apiKey}/${zoomedVideo}`;

      try {
        // Fetch the video as a blob
        const response = await fetch(videoUrl);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const blob = await response.blob();

        // Create a link element
        const link = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        link.href = url;
        link.download = 'video.mp4'; // Set the desired filename for the video

        // Append to the body
        document.body.appendChild(link);

        // Trigger the download
        link.click();

        // Clean up
        link.remove();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Download failed:', error);
      }
    } else {
      console.error('Video URL is not provided');
    }
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      Math.min(prevIndex + 1, data.carousel.length - 1)
    );
  };

  const opacityStyle = mediaAvailablity ? { opacity: 0.5 } : {};
  const overlayStyle = mediaAvailablity
    ? { backgroundColor: 'rgba(0, 0, 0, 0.7)', color: 'white' }
    : {};

  const mediaType = data?.template_header?.type;

  useEffect(() => {
    if (updateUpload) {
      selectMedia();
      setUpdateUpload(false);
    }
  }, [updateUpload]);

  // for media selecting
  const selectMedia = async () => {
    setOpenDialog(true);
    const response = await ApiCalls.listMedia();

    if (response?.status === 200) {
      setMedia(response?.data);
    }
  };

  const filteredMedia = media?.filter((mediaItem) => {
    return (
      mediaItem?.media_type?.toLowerCase() === mediaType?.toLowerCase() &&
      !mediaItem.expired
    );
  });

  //  select id of an media
  const handleMediaSelection = (media) => {
    onMediaSelect(media?._id);
    setSelectedMedia(media);
  };

  //  For add media
  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    const formData = new FormData();
    formData.append('file', selectedFile);

    ApiCalls.sendMedia(formData)
      .then((response) => {
        Toast({ type: 'success', message: 'Media uploaded successfully' });
        setUpdateUpload(true);
      })
      .catch((error) => {
        console.error('Error sending media:', error);
        Toast({ type: 'error', message: 'Media uploading failed' });
      });
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          // paddingBottom: { xs: '1px', md: '5px', lg: '16px' },
          // px: { xs: '1px', md: '0px', lg: '0px' },
          // padding:"20px",
          position: 'relative',
          cursor: 'pointer'
        }}
        onMouseEnter={() => setCurrentMessageId(id)}
        onMouseLeave={() => setCurrentMessageId(null)}
      >
        {/* Dropdwon Menu Starts */}

        {option && currentMessageId === id && (
          <Box
            sx={{
              position: 'absolute',
              zIndex: 50,
              right: '6px',
              top: '5px'
            }}
          >
            {/* <IconButton onClick={handleClick}> */}
            <img
              src={Dropdown2}
              //onClick={handleClick}
              onClick={(e) => {
                // e.stopPropagation(); // Prevent the event from propagating to parent handlers
                handleContextMenu(e, id); // Open the context menu
              }}
              sx={{
                cursor: 'pointer'
              }}
              width={'13.5px'}
              height={'13.5px'}
              style={{
                padding: 0
              }}
            />
            {/* </IconButton> */}

            {/* Dropdown Menu */}
            <Menu
              anchorEl={anchorEl}
              //open={open}
              open={
                open && contextMenuPosition !== null && currentMessageId === id
              }
              onClose={handleContextClose}
              anchorReference="anchorPosition"
              anchorPosition={
                contextMenuPosition !== null
                  ? {
                      top: contextMenuPosition.mouseY,
                      left: contextMenuPosition.mouseX
                    }
                  : undefined
              }
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              slotProps={{
                paper: {
                  sx: {
                    bgcolor: 'white',
                    zIndex: 1000,
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                    borderRadius: '8px',
                    padding: '1px',
                    margin: '2px',
                    mt: '10px'
                  }
                }
              }}
            >
              <div>
                <MenuItem
                  onClick={() => {
                    replyMessage(message), handleContextClose();
                  }}
                >
                  Reply
                </MenuItem>
                <MenuItem onClick={() => copyToClipboard(data?.template_body)}>
                  Copy
                </MenuItem>
                {/* <MenuItem
                // onClick={() =>
                //   handleStatusChange(message?._id, 'pin')
                // }
                >
                  Pin
                </MenuItem> */}
                <MenuItem
                  onClick={() => {
                    handleOpenDialog(id, 'delete'), handleContextClose();
                  }}
                >
                  Delete
                </MenuItem>
              </div>
            </Menu>
          </Box>
        )}

        {/* Dropdown Menu Ends */}

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            position: 'relative'
          }}
        >
          <Box
            sx={{
              position: 'relative',
              maxWidth: '380px',
              minWidth: minWidth ? minWidth : '270px'
            }}
          >
            <Box
              sx={{
                position: 'relative',
                background: bgColor ? bgColor : '#FFFFFF',
                borderRadius: '10px',
                boxShadow: '2px 13px 29px 0px #7878781A',

                // boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.15)',
                '::before': {
                  // content: '""',
                  content: chatPage ? '""' : 'none',
                  position: 'absolute',
                  bottom: '0px',
                  right: '-9px',
                  borderWidth: '10px',
                  borderStyle: 'solid',
                  transform: 'rotate(180deg)',
                  borderColor: `${
                    bgColor ? bgColor : '#FFFFFF'
                  } transparent transparent  transparent `,
                  ...insideTemplate
                }
              }}
            >
              {/* <Box sx={{ maxWidth: '300px', padding: '10px' }}> */}
              <Box sx={{ padding: '10px' }}>
                {data.template_header && (
                  <>
                    {data?.template_header?.type === 'text' && (
                      <Typography
                        my="10px"
                        fontSize="1rem"
                        fontWeight="500"
                        color="#000000"
                      >
                        {data?.template_header?.value}
                      </Typography>
                    )}

                    {data?.template_header?.type === 'image' && (
                      <Box
                        onClick={() => {
                          if (
                            !mediaAvailablity ||
                            mediaAvailablity === 'available'
                          ) {
                            handleOpenZoom(
                              `${data?.template_header?.value?.replace(
                                /^\.\//,
                                ''
                              )}`
                            );
                          }
                        }}
                        sx={{ position: 'relative', display: 'inline-block' }}
                      >
                        <img
                          src={`${apiKey}/${(selectedMedia
                            ? selectedMedia.media_url
                            : data?.template_header?.value
                          )?.replace(/^\.\//, '')}`}
                          alt=""
                          style={{
                            maxWidth: '100%',
                            height: 'auto',
                            ...opacityStyle
                          }}
                        />
                        {mediaAvailablity && (
                          <StyledOverlayBox
                            sx={overlayStyle}
                            onClick={selectMedia}
                          >
                            <Typography
                              sx={{
                                color: '#FFFFFF',
                                fontWeight: 500,
                                fontSize: '15px'
                              }}
                            >
                              Choose media
                            </Typography>
                          </StyledOverlayBox>
                        )}
                      </Box>
                    )}

                    {data?.template_header?.type === 'video' && (
                      <Box
                        onClick={() =>
                          handleZoomVideo(
                            `${data?.template_header?.value?.replace(
                              /^\.\//,
                              ''
                            )}`
                          )
                        }
                      >
                        <video
                          controls
                          style={{
                            maxWidth: '100%',
                            height: 'auto',
                            ...opacityStyle
                          }}
                        >
                          <track kind="captions" src="" label="English" />
                          <source
                            src={`${apiKey}/${(selectedMedia
                              ? selectedMedia.media_url
                              : data?.template_header?.value
                            )?.replace(/^\.\//, '')}`}
                            type="video/mp4"
                          />
                          Your browser does not support the video tag.
                        </video>
                        {mediaAvailablity && (
                          <StyledOverlayBox
                            sx={overlayStyle}
                            onClick={selectMedia}
                          >
                            <Typography
                              sx={{
                                color: '#FFFFFF',
                                fontWeight: 500,
                                fontSize: '15px'
                              }}
                            >
                              Choose media
                            </Typography>
                          </StyledOverlayBox>
                        )}
                      </Box>
                    )}

                    {data?.template_header?.type === 'application' ||
                      (data?.template_header?.type === 'document' && (
                        <Box
                          sx={{ position: 'relative', display: 'inline-block' }}
                        >
                          <iframe
                            src={`https://docs.google.com/viewer?url=${apiKey}/${(selectedMedia
                              ? selectedMedia.media_url
                              : data?.template_header?.value
                            )?.replace(/^\.\//, '')}&embedded=true&rm=minimal`}
                            style={{
                              width: '100%',
                              height: '300px',
                              border: 'none',
                              ...opacityStyle
                            }}
                          />
                          {mediaAvailablity && (
                            <StyledOverlayBox
                              sx={overlayStyle}
                              onClick={selectMedia}
                            >
                              <Typography
                                sx={{
                                  color: '#FFFFFF',
                                  fontWeight: 500,
                                  fontSize: '15px'
                                }}
                              >
                                Choose media
                              </Typography>
                            </StyledOverlayBox>
                          )}
                        </Box>
                      ))}
                  </>
                )}

                {/* Body */}
                {/* {data.template_body && (
                  <Typography
                    my="10px"
                    fontSize=".8rem"
                    fontWeight="400"
                    color="#000000"
                    style={{ wordWrap: 'break-word' }}
                  >
                    {data.template_body}
                  </Typography>
                )} */}
                {/* Body */}
                {data.template_body && (
                  <Typography
                    my="10px"
                    fontSize=".8rem"
                    fontWeight="400"
                    color="#000000"
                    style={{ wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}
                  >
                    {data.template_body.split('\n').map((line, index) => (
                      <React.Fragment key={index}>
                        {line
                          .split(
                            /(\*\*.*?\*\*|\*.*?\*|_.*?_|\~.*?~|\*\_.*?\*|\*~.*\*|\~\*\_.*\~|\~\*.*\*|~\*.*\*)/g
                          ) // Split based on valid patterns
                          .filter((part) => part.trim() !== '') // Remove empty parts
                          .map((part, i) => {
                            // Detect combinations of formatting and apply styles
                            let textStyle = {};
                            let cleanText = part;

                            // Check for Bold
                            if (cleanText.includes('*')) {
                              textStyle.fontWeight = 'bold';
                              cleanText = cleanText.replace(/\*/g, ''); // Remove * after detecting bold
                            }

                            // Check for Italic
                            if (cleanText.includes('_')) {
                              textStyle.fontStyle = 'italic';
                              cleanText = cleanText.replace(/_/g, ''); // Remove _ after detecting italic
                            }

                            // Check for Strikethrough
                            if (cleanText.includes('~')) {
                              textStyle.textDecoration = 'line-through';
                              cleanText = cleanText.replace(/~/g, ''); // Remove ~ after detecting strike
                            }

                            // Apply dynamic styles based on combinations
                            return (
                              <span key={i} style={textStyle}>
                                {cleanText}
                              </span>
                            );
                          })}
                        {index < data.template_body.split('\n').length - 1 && (
                          <br />
                        )}
                      </React.Fragment>
                    ))}
                  </Typography>
                )}

                {data?.category === 'AUTHENTICATION' && (
                  <Box>
                    <Typography>xxxx is your verification code.</Typography>
                  </Box>
                )}

                {/* Footer */}
                {data.template_footer && (
                  // <Typography
                  //   my="10px"
                  //   fontSize=".7rem"
                  //   fontWeight="400"
                  //   color="#8F8F8F"
                  // >
                  //   {data.template_footer}
                  // </Typography>
                  <Typography
                    my="10px"
                    fontSize=".7rem"
                    fontWeight="400"
                    color="#8F8F8F"
                  >
                    {data.template_footer.split(' ').map((word, index) => {
                      // Check if the word is a number (assuming phone numbers)
                      const isPhoneNumber = /^\d{10}$/.test(word); // You can adjust the pattern as per your needs

                      // Check if the word is a valid URL (link)
                      const isLink =
                        /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i.test(word);

                      if (isPhoneNumber) {
                        // If it's a phone number, wrap it in a tel link
                        return (
                          <React.Fragment key={index}>
                            <a
                              href={`tel:${word}`}
                              style={{
                                color: '#8F8F8F',
                                textDecoration: 'none'
                              }}
                            >
                              {word}
                            </a>{' '}
                          </React.Fragment>
                        );
                      } else if (isLink) {
                        // If it's a link, wrap it in a hyperlink
                        return (
                          <React.Fragment key={index}>
                            <a
                              href={word}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                color: 'blue',
                                textDecoration: 'none'
                              }}
                            >
                              {word}
                            </a>{' '}
                          </React.Fragment>
                        );
                      } else {
                        // Otherwise, just render the word as plain text
                        return (
                          <React.Fragment key={index}>{word} </React.Fragment>
                        );
                      }
                    })}
                  </Typography>
                )}
              </Box>

              {/* Buttons */}
              {data.template_buttons && (
                <Box>
                  {data?.template_buttons?.map((button, buttonIndex) => (
                    <Box
                      key={buttonIndex}
                      padding="10px"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      cursor="pointer"
                      backgroundColor={bgColor ? bgColor : '#FFFFFF'}
                      color="#168edf"
                      fontSize="14px"
                      fontWeight="500"
                      borderTop="1px solid #cbcbcb8c" // Add border to the top side of each button
                      sx={{
                        borderBottomLeftRadius: '10px',
                        borderBottomRightRadius: '10px'
                      }}
                    >
                      {renderButtonIcon(button.type)} {/* Render button icon */}
                      {button.text}{' '}
                    </Box>
                  ))}
                </Box>
              )}

              {/* Carousel */}
              {/* <Box
                sx={{
                  position: 'relative',
                  maxWidth: '280px',
                  overflow: 'hidden'
                }}
              > */}
              <Box
                sx={{
                  position: 'relative',
                  maxWidth: '300px',
                  overflow: 'hidden',
                  borderRadius: '10px',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
                }}
              >
                {/* <IconButton
                  onClick={handlePrevious}
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '0',
                    transform: 'translateY(-50%)',
                    zIndex: 1
                  }}
                >
                  <ArrowBackIosIcon />
                </IconButton> */}
                <IconButton
                  onClick={handlePrevious}
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '5px',
                    padding: '5px',
                    width: '5px',
                    transform: 'translateY(-50%)',
                    zIndex: 1,
                    backgroundColor: '#ECECEC',
                    '&:hover': {
                      backgroundColor: '#B5B5B5'
                    }
                  }}
                >
                  <ArrowBackIosIcon />
                </IconButton>
                <Box
                  sx={{
                    display: 'flex',
                    transition: 'transform 0.3s ease-in-out',
                    transform: `translateX(-${currentIndex * 290}px)`
                  }}
                >
                  {data.carousel &&
                    data.carousel.map((item, index) => (
                      <Box
                        key={index}
                        sx={{
                          borderRadius: '8px',
                          backgroundColor: '#FFFFFF',
                          px: { xs: '1px', md: '3px', lg: '5px' },
                          minWidth: '270px',
                          maxWidth: '270px',
                          mr: 2
                        }}
                      >
                        {item.carousel_header?.type === 'image' && (
                          <Box
                            sx={{
                              position: 'relative',
                              display: 'inline-block',
                              maxHeight: '200px',
                              maxWidth: '270px'
                            }}
                          >
                            <img
                              src={`${apiKey}/${item.carousel_header.url?.replace(
                                /^\.\//,
                                ''
                              )}`}
                              alt=""
                              style={{
                                maxWidth: '100%',
                                maxHeight: '150px',
                                width: '270px',
                                height: '300px',
                                objectFit: 'cover'
                              }}
                              onClick={() =>
                                handleOpenZoom(
                                  `${item.carousel_header.url?.replace(
                                    /^\.\//,
                                    ''
                                  )}`
                                )
                              }
                            />
                          </Box>
                        )}
                        {item.carousel_header?.type === 'video' && (
                          <Box
                            sx={{
                              position: 'relative',
                              display: 'inline-block'
                            }}
                          >
                            <video
                              controls
                              style={{ maxWidth: '100%', height: 'auto' }}
                              onClick={() =>
                                handleZoomVideo(
                                  `${item?.carousel_header.url.replace(
                                    /^\.\//,
                                    ''
                                  )}`
                                )
                              }
                            >
                              <track kind="captions" src="" label="English" />
                              <source
                                src={`${apiKey}/${item.carousel_header.url?.replace(
                                  /^\.\//,
                                  ''
                                )}`}
                                type="video/mp4"
                              />
                              Your browser does not support the video tag.
                            </video>
                          </Box>
                        )}

                        <Typography
                          my="10px"
                          fontSize=".8rem"
                          fontWeight="400"
                          color="#000000"
                          style={{
                            wordWrap: 'break-word',
                            whiteSpace: 'pre-wrap'
                          }}
                        >
                          {item.carousel_body.split('\n').map((line, index) => (
                            <React.Fragment key={index}>
                              {line}
                              {index <
                                item.carousel_body.split('\n').length - 1 && (
                                <br />
                              )}
                            </React.Fragment>
                          ))}
                        </Typography>
                        {item.carousel_buttons.map((button, buttonIndex) => (
                          <Box
                            key={buttonIndex}
                            padding="10px"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            cursor="pointer"
                            backgroundColor="#FFFFFF"
                            color="#168edf"
                            fontSize="14px"
                            fontWeight="500"
                            borderTop="1px solid #cbcbcb8c"
                            sx={{
                              marginRight: '8px',
                              marginBottom: '8px',
                              borderBottomLeftRadius: '10px',
                              borderBottomRightRadius: '10px'
                            }}
                          >
                            {renderButtonIcon(button.type)} {button.text}
                          </Box>
                        ))}
                      </Box>
                    ))}
                </Box>
                <IconButton
                  onClick={handleNext}
                  sx={{
                    position: 'absolute',
                    padding: '5px',
                    top: '50%',
                    right: '5px',
                    transform: 'translateY(-50%)',
                    zIndex: 1,
                    backgroundColor: '#ECECEC',
                    '&:hover': {
                      backgroundColor: '#B5B5B5'
                    }
                  }}
                >
                  <ArrowForwardIosIcon />
                </IconButton>
              </Box>

              {chatPage && !insideTemplate && (
                <Typography
                  textAlign="right"
                  color="#7a7a7a"
                  fontSize=".7rem"
                  padding="0px 5px 0px 5px"
                >
                  {/* {chatPage && data?.createdAt && (
                    <span>{format(new Date(data.createdAt), 'h:mm a')}</span>
                  )} */}

                  {/* Tick Icon */}
                  {/* Tick Icon based on Status */}
                  <span style={{ marginLeft: '4px' }} />
                  {status && status === 'sent' && (
                    <DoneIcon
                      style={{ fontSize: 'small', verticalAlign: 'middle' }}
                    />
                  )}
                  {status && status === 'delivered' && (
                    <DoneAllIcon
                      style={{ fontSize: 'small', verticalAlign: 'middle' }}
                    />
                  )}
                  {status &&
                  (status === 'read' ||
                    status === 'replied' ||
                    status === 'opened') ? ( //Changed
                    <DoneAllIcon
                      style={{
                        fontSize: 'small',
                        color: '#488BE9',
                        verticalAlign: 'middle'
                      }}
                    />
                  ) : null}
                  {status && status === 'failed' && (
                    <CustomTooltip
                      title={
                        errorMessage
                          ? errorMessage
                          : 'Message not delivered to user because of frequency capping or number not on WhatsApp'
                      }
                      placement="left"
                      arrow
                      sx={{
                        '& .MuiTooltip-arrow': {
                          color: '#ff2f2f' // Replace `yourColorHere` with the desired arrow color
                        }
                      }}
                    >
                      <img src={failedIcon} height={16} width={16} />
                    </CustomTooltip>
                  )}
                </Typography>
              )}
            </Box>

            <Box
              textAlign="right"
              color="#7a7a7a"
              fontSize="11px"
              padding="0px 5px 0px 5px"
              mt={'4px'}
            >
              {chatPage && data?.createdAt && (
                <span>{format(new Date(data.createdAt), 'h:mm a')}</span>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      {/* Dailogbox starts */}

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        maxWidth="md"
      >
        <DialogTitle sx={{ fontSize: 18 }}>Media Gallery</DialogTitle>

        <DialogContent
          sx={{
            overflowY: 'scroll',
            '&::-webkit-scrollbar': { display: 'none' }
          }}
        >
          <Grid item sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Input
              accept="
            audio/aac,
            audio/mp4,
            audio/mpeg,
            audio/amr,
            audio/ogg,
            text/plain,
            application/pdf,
            application/vnd.ms-powerpoint,
            application/msword,
            application/vnd.ms-excel,
            application/vnd.openxmlformats-officedocument.wordprocessingml.document,
            application/vnd.openxmlformats-officedocument.presentationml.presentation,
            application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
            image/jpeg,
            image/png,
            image/webp,
            video/mp4,
            video/3gp,
            sticker"
              id="contained-button-file"
              multiple
              type="file"
              onChange={handleFileChange}
              style={{ display: 'none' }}
            />
            <label htmlFor="contained-button-file">
              <Button
                sx={{ bgcolor: '#27AB69', color: '#ffffff', ml: 1 }}
                variant="contained"
                component="span"
                endIcon={<AddIcon />}
              >
                Add
              </Button>
            </label>
          </Grid>
          <Grid
            container
            sx={{ justifyContent: { xs: 'center', md: 'flex-start' } }}
          >
            {filteredMedia?.map((media) => (
              <Card
                key={media._id}
                sx={{
                  maxWidth: 300,
                  margin: 1,
                  position: 'relative' // Ensure the card acts as the positioning context
                }}
              >
                {/* Media content */}
                <div
                  style={{
                    position: 'relative',
                    width: '100%',
                    height: 155
                  }}
                >
                  {/* Media */}
                  {mediaType === 'image' && media.media_type === 'image' && (
                    <CardMedia
                      component="img"
                      onClick={() => handleMediaSelection(media)}
                      style={{
                        minWidth: '200px',
                        maxWidth: '200px', // Ensuring the video stays within container width
                        width: '100%',
                        height: '100%', // Allowing height to adjust proportionally
                        objectFit: 'cover'
                      }}
                      src={`${apiKey}/${media.media_url?.replace(/^\.\//, '')}`}
                      alt={media.media_id}
                    />
                  )}
                  {mediaType === 'video' && media.media_type === 'video' && (
                    <CardMedia
                      component="video"
                      onClick={() => handleMediaSelection(media)}
                      style={{
                        minWidth: '140px',
                        maxWidth: '200px',
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover'
                      }}
                      controls
                      src={`${apiKey}/${media.media_url?.replace(/^\.\//, '')}`}
                      alt={media.media_id}
                    />
                  )}
                  {mediaType === 'application' &&
                    media.media_type === 'application' && (
                      <iframe
                        // src={`https://docs.google.com/viewer?url=${apiKey}/${media.media_url?.replace(
                        //   /^\.\//,
                        //   ''
                        // )}&embedded=true`}
                        src={`https://docs.google.com/viewer?url=${encodeURIComponent(
                          `${apiKey}/${(selectedMedia
                            ? selectedMedia.media_url
                            : data?.template_header?.value
                          )?.replace(/^\.\//, '')}`
                        )}&embedded=true&rm=minimal`}
                        title={media.media_id}
                        style={{
                          width: '100%',
                          height: '100%',
                          minWidth: '155px',
                          objectFit: 'cover',
                          border: 'none'
                        }}
                      />
                    )}
                  {/* Checkbox */}
                  <div
                    style={{
                      position: 'absolute',
                      bottom: 0,
                      right: 0
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          // className={classes.checkbox}
                          style={{
                            position: 'absolute',
                            bottom: 0,
                            right: 0,
                            transform: 'scale(0.8)',
                            checkbox: {
                              '&.Mui-checked': {
                                color: '#27AB69' // Color when checked
                              },
                              '& .MuiIconButton-root': {
                                borderColor: '#27AB69', // Border color
                                backgroundColor: '#27AB69' // Background color when not checked
                              },
                              '& .MuiSvgIcon-root': {
                                backgroundColor: 'rgba(255, 255, 255, 0.5)', // Background color when not checked
                                borderRadius: 4 // Add border radius to the inner box
                              }
                            }
                          }}
                          checked={selectedMedia._id === media._id}
                          onChange={() => handleMediaSelection(media)}
                        />
                      }
                    />
                  </div>
                </div>
              </Card>

              // </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenDialog(false)}
            sx={{ color: '#27AB69', fontWeight: 500 }}
          >
            Close
          </Button>
          <Button
            // onClick={handleConfirmMedia}
            onClick={() => setOpenDialog(false)}
            sx={{ color: '#27AB69', fontWeight: 500 }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      {/* Zoomed View of the Image */}

      {zoomedMedia && (
        <Modal
          open={open}
          onClose={handleCloseZoom}
          aria-labelledby="full-screen-modal"
          aria-describedby="full-screen-modal-description"
          sx={{
            display: 'flex',
            alignItems: 'center',
            position: 'absolute',
            justifyContent: 'center'
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 1)', // Optional: Semi-transparent background
              backdropFilter: 'blur(8px)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 999
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: '40px',
                right: '25px',
                display: 'flex',
                gap: '20px',
                color: 'lightgray'
              }}
            >
              <Box>
                <img
                  src={Download}
                  onClick={handleDownload}
                  style={{ width: '25px', height: '25px', cursor: 'pointer' }}
                />
              </Box>
              <Box onClick={handleCloseZoom}>
                <img
                  src={Close}
                  style={{ width: '25px', height: '25px', cursor: 'pointer' }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                width: {
                  xs: '100%',
                  sm: '80%',
                  md: '60%',
                  lg: '60%'
                },
                height: { xs: '100%', sm: '80%', lg: '60%' },
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center'
              }}
            >
              <img
                src={`${apiKey}/${zoomedMedia}`}
                // src={zoomedMedia}
                alt="Popup Content"
                style={{
                  width: '100%',
                  borderRadius: '2px',
                  height: '100%',
                  objectFit: 'contain' // Ensure the image maintains its aspect ratio
                }}
              />
            </Box>
          </Box>
        </Modal>
      )}

      {/* Zoomed View of the Video */}
      {zoomedVideo && (
        <Modal
          open={open}
          onClose={handleCloseVideo}
          aria-labelledby="full-screen-modal"
          aria-describedby="full-screen-modal-description"
          sx={{
            display: 'flex',
            alignItems: 'center',
            position: 'absolute',
            justifyContent: 'center'
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 1)', // Optional: Semi-transparent background
              backdropFilter: 'blur(8px)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 999
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: '40px',
                right: '25px',
                display: 'flex',
                gap: '20px',
                color: 'lightgray'
              }}
            >
              <Box>
                <img
                  src={Download}
                  onClick={handleVideoDownload}
                  style={{ width: '25px', height: '25px', cursor: 'pointer' }}
                />
              </Box>
              <Box onClick={handleCloseVideo}>
                <img
                  src={Close}
                  style={{ width: '25px', height: '25px', cursor: 'pointer' }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                width: {
                  xs: '100%',
                  sm: '80%',
                  md: '60%',
                  lg: '60%'
                },
                height: { xs: '100%', sm: '80%', lg: '60%' },
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center'
              }}
            >
              <video
                controls
                preload="metadata"
                style={{
                  width: '100%',
                  height: '100%'
                }}
              >
                <track kind="captions" src="" label="English" />
                <source
                  src={`${apiKey}/${zoomedVideo}`}
                  // src={selectedMedia.example}
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default TemplatePreview;
