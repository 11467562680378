import { Box, Typography } from '@mui/material';
import React from 'react'
import ChatBG from 'src/assets/TemplateIcons/BG.svg';
import TemplateChatBgPreview from './TemplateChatBgPreview';
import styled from '@emotion/styled';


const TemplateBg = ({data,width}) => {
  const RightSide = styled(Box)(() => ({
    backgroundColor: '#ffffff',
    // borderRadius: '10px',
    width:width?width:"400px",
    position:"sticky",
    top:"90px",
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    // padding: '15px 30px',
    boxShadow: '0px 3px 4.5px rgba(0, 0, 0, 0.1)'
  }));
  return (
    <RightSide>
    <Typography
      fontSize="16px"
      fontWeight={500}
      color="#111111"
      style={{padding:"20px"}}
    >
      Template Preview
    </Typography>
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      sx={{ backgroundImage: ` url(${ChatBG})`,}}
    >
      <TemplateChatBgPreview data={data} bgColor={'white'} chatPage={true} minWidth={"257px"} insideTemplate={{top:"0px",right:"9px",left:"-9px",  transform: 'rotate(0deg)',}} />
    </Box>
  </RightSide>
  )
}

export default TemplateBg
