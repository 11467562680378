import React, { useEffect, useState } from 'react';
import CustomDialogie from 'src/components/dialogue/CustomDialogie';
import TemplateContent from '../TemplateContent';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import TemplateApiCalls from 'src/utility/TemplateApiCalls';
import TemplateLibContainer from './TemplateLibContainer';

const TemplateLibrary = () => {
  const fetchData = async ({ pageParam }) => {
    try {
      console.log('pageParams', pageParam);
      let page=""
      if (pageParam) {
        page = `&after=${pageParam}`;
      } 
      const response = await TemplateApiCalls.listAllTemplatesLibrary(page);
      // const response = await TemplateApiCalls.listAllTemplatesLibrary();
      return response?.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  //   const {
  //     data: allTemplates,
  //     isLoading,
  //     isError
  //   } = useQuery({
  //     queryKey: ['templateslib'],
  //     queryFn: fetchData,
  //     staleTime: 10000,
  //     cacheTime: 20000,pageParams
  //   });

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, refetch } =
    useInfiniteQuery({
      queryKey: ['templateslib'],
      queryFn: fetchData,
      getNextPageParam: (lastPage) => {
          if (lastPage?.pagination?.nextPageUrl) {
            console.log("lastPage?.pagination?.nextPageUrl",lastPage?.pagination?.nextPageUrl);
          return lastPage?.pagination?.nextPageUrl;
        } else {
          return null;
        }
      }
    });

  const handleScroll = (event) => {
    if (
      event.target.scrollHeight -50  <=
      event.target.scrollTop + event.target.clientHeight
    ) {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    }
  };
  const [libTemplate, setLibTemplate] = useState([]);
  useEffect(() => {
    if (data) {
      // Rebuild uniqueOrders completely when filters change or new data arrives
      const filteredOrders = [];

      data?.pages?.forEach((page) => {
        page?.templates?.forEach((order) => {
          if (
            !filteredOrders.some(
              (existingOrder) => existingOrder.template_id === order.template_id
            )
          ) {
            filteredOrders.push(order);
          }
        });
      });

      setLibTemplate(filteredOrders); // Replace old data with filtered data
    }
  }, [data]);

  const isLoading = false;
  
  return (
    // <CustomDialogie
    //   width={'90vw'}
    //   openTemplateDialog={showLib}
    //   performCancel={performCancel}
    // >
    <TemplateLibContainer
    handleScroll={handleScroll}
    libTemplate={libTemplate}
    loading={isLoading}
    hasNextPage={hasNextPage}
    />
   
    // </CustomDialogie>
  );
};

export default TemplateLibrary;
