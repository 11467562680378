import { Box, IconButton, InputBase } from '@mui/material';
import React, { useCallback, useState } from 'react';
import styled from '@emotion/styled';
import searchIcon from 'src/assets/svgIcons/search.svg';
import { AnimatePresence, motion } from 'framer-motion';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ClearIcon from '@mui/icons-material/Clear';

const SearchInput = styled(InputBase)(
  ({ theme }) => `
      flex: 1;
      margin-left: ${theme.spacing(1)};
      color:#959595;
      size:'18px';
    `
);
const SearchBarWrapper = styled(Box)(
  ({ theme }) => `
      display: flex;
      align-items: center;
      padding: 0px ${theme.spacing(1)};
      background-color: #ffffff;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      height: 100%; 
      overflow: hidden; 
      width:200px;
    `
);
const SearchCustom = ({searchQuery,setSearchQuery,search,setSearch}) => {
  const [searchApplied, setSearchApplied] = useState(false);

  const handleChange = useCallback((event) => {
    setSearchQuery(event.target.value);
    setSearchApplied(true);
  });
  const handleClear = () => {
    setSearchQuery('');
  };
  return (
    <>
      {!search && (
        <Box
          sx={{
            width: 'fit-content',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            px: '10px',
            py: '4px',
            // borderRadius: '10px',
            bgcolor: '#ffffff',
            borderTopRightRadius: '10px',
            borderBottomRightRadius: '10px'
          }}
          onClick={() => setSearch(!search)}
        >
          <img
            src={searchIcon}
            style={{
              color: '#C5C5C5',
              mt: '3px',
              height: '22px',
              width: '22px'
            }}
          />
        </Box>
      )}
      {search && (
        <motion.div
          initial={{ width: '0%', opacity: 0 }}
          animate={{  opacity: 1 }}
          exit={{ width: '0%', opacity: 0 }}
          transition={{
            duration: 0.4,
            ease: 'easeOut'
          }}
          style={{ width: '100%' }}
        >
          <SearchBarWrapper>
            <ArrowBackIosIcon
              style={{
                cursor: 'pointer',
                color: 'gray',
                fontSize: '18px',
                marginLeft: '5px'
              }}
              onClick={() => {
                setSearch(false), setSearchQuery('');
              }}
            />
            <SearchInput
              placeholder="Search..."
              value={searchQuery}
              onChange={handleChange}
              autoFocus
              sx={{ width: '100%' }}
            />
            {searchQuery && (
              <IconButton onClick={handleClear}>
                <ClearIcon
                  sx={{
                    color: '#C5C5C5',
                    height: '22px',
                    width: '22px'
                  }}
                />
              </IconButton>
            )}
          </SearchBarWrapper>
        </motion.div>
      )}
    </>
  );
};

export default SearchCustom;
