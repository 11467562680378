import React, { useState } from "react";
import { Box, InputAdornment, Button, TextField } from "@mui/material";
const QuickReply = ({multipleButtons,setMultipleButtons,disable}) => {
console.log("Quick BUttons",multipleButtons)
  // Handle input change for a specific button
  const handleButtonChange = (index, value) => {
    const updatedButtons = [...multipleButtons];
    updatedButtons[index].text = value;
    setMultipleButtons(updatedButtons);
  };

  // Add a new button
  const handleAddButton = () => {
    setMultipleButtons([...multipleButtons, { text: "",type:"QUICK_REPLY" }]);
  };

  // Remove a button
  const handleRemoveButton = (index) => {
    const updatedButtons = multipleButtons.filter((_, i) => i !== index);
    setMultipleButtons(updatedButtons);
  };
  return (
    <>
    {multipleButtons.map((button, index) => (
      <Box
        key={index}
        bgcolor="#F6F6F6"
        padding="10px"
        borderRadius="10px"
        marginBottom="10px"
      >
        <Box>
          <TextField
            placeholder="Button text"
            variant="standard"
            fullWidth
            value={button.text}
            onChange={(e) => handleButtonChange(index, e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{
                    position: "absolute",
                    bottom: 10,
                    right: 5,
                  }}
                >
                  <span style={{ fontSize: "12px", color: "#888" }}>
                    {button.text?.length}/25
                  </span>
                </InputAdornment>
              ),
            }}
            error={Boolean(button.text?.length > 24)}
            helperText={
              button.text?.length > 24 ? "Maximum Limit Reached" : ""
            }
            style={{ marginBottom: "10px" }}
          />
    {!disable&&

          <Button
            variant="outlined"
            color="error"
            onClick={() => handleRemoveButton(index)}
            style={{ marginTop: "5px" }}
            // disabled={multipleButtons.length === 1} // Disable if only one button exists
          >
            Remove
          </Button>
        }        </Box>
      </Box>
    ))}

    {/* Add Button */}
    {!disable&&
      
    <Button
    variant="contained"
    color="primary"
    onClick={handleAddButton}
    style={{ marginTop: "10px" }}
    disabled={multipleButtons.length >= 7} // Limit the number of multipleButtons to 3
    >
      Add Button
    </Button>
    }
  </>
  )
}

export default QuickReply
