import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useParams } from 'react-router';
import NewTemplateCarosel from 'src/content/applications/NewTemplatesCarosel';
import TemplateApiCalls from 'src/utility/TemplateApiCalls';

const fetchTemplateById = async (templateId) => {
  const response = await TemplateApiCalls.getTemplatesByIdForEdit(templateId);
  return response.data;
};

const EditTemplate = () => {
  const { id } = useParams();

  const { data, error, isLoading } = useQuery({
    queryKey: ['template', id],  // This is the query key (required for TanStack Query)
    queryFn: () => fetchTemplateById(id),  // This is the function to fetch the data
    enabled: !!id,  // Ensures the query only runs when `id` is available
  });

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error fetching template: {error.message}</div>;

  let isEditedRecently = false;

  if (data && data?.editedTime) {
    // Get the current date
    const currentDate = new Date();

    // Calculate the date 4 days ago
    const fourDaysAgo = new Date();
    fourDaysAgo.setDate(currentDate.getDate() - 4);

    // Compare editedTime with 4 days ago
    const editedTime = new Date(data.editedTime);

    // If editedTime is within the last 4 days, set isEditedRecently to true
    if (editedTime >= fourDaysAgo && editedTime <= currentDate) {
      isEditedRecently = true;
    }
  }

  return (
    <div>
      {isEditedRecently && (
        <p style={{ color: 'orange' }}>Warning: This template was edited within the last 4 days!</p>
      )}
      <NewTemplateCarosel initialData={data}/>
    </div>
  );
};

export default EditTemplate;
