import React, { useState } from 'react';
import { Box, InputAdornment, Button, TextField, Typography } from '@mui/material';
import { styled } from '@mui/system';

const InnerButtonTextField = styled(TextField)`
  // Add your custom styles here if needed
`;

const ButtonTextMAxCount = styled(Typography)`
  font-size: 12px;
  color: #888;
`;

const ErrorText = styled(Typography)`
  font-size: 12px;
  color: red;
`;

const CallToAction = ({multipleCallToActionUrl,SetMultipleCallToActionUrl,disable}) => {
 
  const handleAddCallToAction = () => {
    SetMultipleCallToActionUrl([
      ...multipleCallToActionUrl,
      { text: '', type: 'URL',url:"" }

    ]);
  };
console.log(multipleCallToActionUrl)
  const handleRemoveCallToAction = (index) => {
    const updatedCallToActions = multipleCallToActionUrl.filter((_, i) => i !== index);
    SetMultipleCallToActionUrl(updatedCallToActions);
  };

  const handleChange = (index, field, value) => {
    const updatedCallToActions = [...multipleCallToActionUrl];
    updatedCallToActions[index][field] = value;

    if (field === 'text' && value.length > 25) {
      // updatedCallToActions[index].textError = true;
    } else if (field === 'url' && !/^https?:\/\/.+/.test(value)) {
      // updatedCallToActions[index].urlError = true;
    } else {
      // updatedCallToActions[index][`${field}Error`] = false;
    }

    SetMultipleCallToActionUrl(updatedCallToActions);
  };

  return (
    <Box>
      {multipleCallToActionUrl.map((action, index) => (
        <Box
          key={index}
          bgcolor="#F6F6F6"
          padding="10px"
          borderRadius="10px"
          marginBottom="10px"
        >
          <Typography variant="h6" gutterBottom>
            URL {index + 1}
          </Typography>

          {/* Button Text Field */}
          <InnerButtonTextField
            placeholder="Button Text"
            variant="standard"
            fullWidth
            value={action.text}
            onChange={(e) => handleChange(index, 'text', e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{
                    position: 'absolute',
                    bottom: 10,
                    right: 5,
                  }}
                >
                  <ButtonTextMAxCount>
                    {action.text?.length}/25
                  </ButtonTextMAxCount>
                </InputAdornment>
              ),
            }}
            error={Boolean(action.textError)}
            helperText={
              action.textError ? 'Maximum Limit Reached' : ''
            }
            style={{ marginBottom: '10px' }}
          />

          {action.textError && (
            <ErrorText>Please enter valid button text</ErrorText>
          )}

          {/* URL Field */}
          <InnerButtonTextField
            placeholder="Type your URL"
            variant="standard"
            fullWidth
            value={action.url}
            onChange={(e) => handleChange(index, 'url', e.target.value)}
            style={{ marginBottom: '10px' }}
            error={Boolean(action.urlError)}
            helperText={action.urlError ? 'Please enter a valid URL' : ''}
          />

          {action.urlError && (
            <ErrorText>Please enter a valid URL</ErrorText>
          )}

          {/* Remove Button */}
          {!disable&&multipleCallToActionUrl.length > 0 && (
            <Button
              variant="outlined"
              color="error"
              onClick={() => handleRemoveCallToAction(index)}
              style={{ marginTop: '10px' }}
            >
              Remove
            </Button>
          )}
        </Box>
      ))}

      {/* Add Button */}
      {!disable&&multipleCallToActionUrl.length < 2 && (

      <Button
        variant="contained"
        onClick={handleAddCallToAction}
        style={{ marginTop: '20px' }}
      >
        Add Another URL
      </Button>)}
    </Box>
  );
};

export default CallToAction;
