// ConfirmDialog.js
import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Typography,
  Box
} from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';

const   CommonCofirmation = ({
  open,
  onClose,
  onConfirm,
  loading,
  heading,
  description,
  cancelText,
  confirmText
}) => {
  return (
    <AnimatePresence>
      {open && (
        <Dialog
          open={open}
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              onClose();
            }
          }}
          sx={{
            '& .MuiDialog-paper': {
              backgroundColor: 'transparent',
              boxShadow: 'none'
              // padding: '10px',
              // maxWidth: '500px'
            }
          }}
        >
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            transition={{
              duration: 0.25
            }}
          >
            <Box
              sx={{
                bgcolor: '#F2F2F5',
                p: '10px',
                borderRadius: '8px',
                maxWidth: '500px'
              }}
            >
              <DialogTitle
                sx={{
                  bgcolor: '#F2F2F5',
                  fontSize: 18,
                  fontWeight: 600,
                  fontFamily: 'Poppins, sans-serif',
                  textAlign: 'center'
                }}
              >
                {/* Archive {name} ? */}
                {heading}
                {/* <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            position: 'relative'
          }}
        >
          <CloseIcon
            sx={{
              cursor: 'pointer',
              backgroundColor: '#959595',
              borderRadius: '50%',
              color: '#FFF',
              padding: '2px',
              position: 'absolute',
              top: -35,
              right: -20
            }}
            onClick={() => onClose()}
          />
        </Box> */}
              </DialogTitle>
              <DialogContent
                sx={{
                  bgcolor: '#F2F2F5',
                  fontSize: '13px'
                }}
              >
                <Typography
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    wordBreak: 'break-all',
                    whiteSpace: 'normal'
                  }}
                >
                  {/* Are you sure you want to archive this customer? */}
                  {description}
                </Typography>
              </DialogContent>
              <DialogActions
                sx={{
                  bgcolor: '#F2F2F5',
                  width: '95%',
                  display: 'flex',
                  gap: 2,
                  justifyContent: 'center',
                  alignContent: 'center',
                  mx: 'auto'
                }}
              >
                <Button
                  disableRipple
                  disableFocusRipple
                  sx={{
                    backgroundColor: '#F2F2F5',
                    fontWeight: 500,
                    color: '#27AB69',
                    width: '80%',
                    mb: '5px',
                    border: '1px solid #27AB69',
                    height: '35px',
                    '&:hover': {
                      backgroundColor: '#F2F2F5'
                    }
                  }}
                  disabled={loading}
                  onClick={() => onClose()}
                >
                  {/* Cancel */}
                  {cancelText}
                </Button>
                <Button
                  disableRipple
                  disableFocusRipple
                  sx={{
                    backgroundColor: '#27AB69',
                    fontWeight: 400,
                    color: '#FFF',
                    width: '80%',
                    mb: '5px',
                    height: '35px',
                    '&:hover': {
                      backgroundColor: '#27AB69'
                    }
                  }}
                  disabled={loading}
                  onClick={() => onConfirm()}
                >
                  {/* Archive */}
                  {confirmText}
                  {loading && (
                    <CircularProgress
                      size={16}
                      sx={{ ml: '15px', color: '#FFF' }}
                    />
                  )}
                </Button>
              </DialogActions>
            </Box>
          </motion.div>
        </Dialog>
      )}
    </AnimatePresence>
  );
};

export default CommonCofirmation;
