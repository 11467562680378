export const features = [
  {
    title: 'Shopify',
    endDate: '2025-1-31'
  },
  {
    title: 'Wordpress',
    endDate: '2025-1-31'
  },
  {
    title: 'Pabbly Connect',
    endDate: '2025-1-31'
  },
  {
    title: 'Zapier',
    endDate: '2025-1-31'
  },
  {
    title: 'Razorpay',
    endDate: '2025-1-31'
  },
  {
    title: 'Custom Attributes',
    endDate: '2025-1-31'
  }
];
