import { Box } from '@mui/material';
// import Footer from 'src/components/Footer';
import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import TemplateContentLib from './TemplateContentLib';

const RootWrapper = styled(Box)(
  ({ theme }) => `
    height: calc(94vh - ${theme.header.height});
    display: flex;
    z-index: 0;
    
    ${theme.breakpoints.down('sm')} {
      height: calc(100vh - ${theme.header.height});
    }
  `
);

const CreateTemplateLib = ({initialData}) => {
      // For Flow forms


  return (
    <>

        <TemplateContentLib  initialData={initialData}/>
      {/* <Footer /> */}
    </>
  )
}

export default CreateTemplateLib
