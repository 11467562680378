

import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useParams } from 'react-router';
import CreateTemplateLib from './CreateTemplateLib';
import CustomDialogie from 'src/components/dialogue/CustomDialogie';


const EditTemplateLib = ({initialData,show,performCancel}) => {



  return (
    <div>
<CustomDialogie openTemplateDialog={show} performCancel={performCancel} width={"90vw"}>

      <CreateTemplateLib initialData={initialData}/>
</CustomDialogie>
    </div>
  );
};

export default EditTemplateLib;
