import React from 'react';
import { Box, Card } from '@mui/material';

const ShimmerLoader = ({
  margin = '0px',
  height = '100px',
  width = '100%'
}) => {
  return (
    <Card
      style={{
        margin,
        height,
        width,
        borderRadius: '8px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.08)',
        overflow: 'hidden' // Ensures the wave effect doesn't spill out of the container
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          background:
            'linear-gradient(135deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)', // Diagonal gradient
          backgroundSize: '200% 100%', // Increase size for smoother animation
          animation: 'waveDiagonal 1.5s infinite linear', // Custom animation
          '@keyframes waveDiagonal': {
            '0%': { backgroundPosition: '150% 150%' }, // Start top-left
            '100%': { backgroundPosition: '-150% -150%' } // End bottom-right
          }
        }}
      />
    </Card>
  );
};

export default ShimmerLoader;
